import Vue from 'vue'
import VueRouter from 'vue-router'
import { ifAuthenticated, ifNotAuthenticated } from '@/router/guards'
import { ifNotRestricted } from './guards'

Vue.use(VueRouter)

const routes = [
  {
    name: 'Login',
    path: '/login',
    beforeEnter: ifNotAuthenticated,
    component: () => import('../views/auth/Login'),
  },
  {
    path: '/',
    redirect: '/dispositivos',
    component: () => import('../views/dashboard/Index'),
    children: [
      {
        name: 'Dispositivos',
        path: 'dispositivos',
        beforeEnter: ifAuthenticated,
        component: () => import('../views/dashboard/Dispositivos'),
      },
      {
        name: 'DetalleDispositivo',
        path: 'dispositivos/:codigo',
        beforeEnter: ifAuthenticated,
        component: () => import('../views/dashboard/dispositivo/Index'),
        redirect: 'dispositivos/:codigo/sensores',
        children: [
          {
            name: 'Sensores',
            path: 'sensores',
            props: true,
            component: () => import('../views/dashboard/dispositivo/Sensores'),
          },
          {
            name: 'TablaGeneralSensores',
            path: 'tabla-general',
            props: true,
            beforeEnter: ifNotRestricted,
            component: () => import('../views/dashboard/dispositivo/TablaGeneralSensores'),
          },
          {
            name: 'Resumen',
            path: 'resumen',
            props: true,
            component: () => import('../views/dashboard/dispositivo/Resumen'),
          },
        ]
      },
    ]
  },
  {
    path: '*',
    redirect: '/dispositivos',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
