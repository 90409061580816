import store from '../store'

export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

export const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}
export const ifNotRestricted = async (to, from, next) => {
  await store.dispatch('perfil/fetchUsuario')
  const isRestrictedUser = store.getters['perfil/isRestrictedUser']
  if (!isRestrictedUser) {
    next()
    return
  }
  next(from)
}
